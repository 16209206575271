import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAllAttachmentError,
  getAllAttachmentSuccess,
  getAllMetadataError,
  getAllMetadataSuccess,
  getRequiredProcessError,
  getRequiredProcessSuccess,
  postMetadataError,
  postMetadataSuccess,
} from "./actions";
import {
  GET_ALL_ATTACHMENT,
  GET_ALL_METADATA,
  GET_REQUIRED_PROCESS,
  POST_METADATA,
} from "./actionTypes";
import {
  getAllDocumentAttachmentAPI,
  getAllRequiredWorkFlowProcessAPI,
  getDocumentMetadataAPI,
  postDocumentMetadataAPI,
} from "../../helpers/call_api";

//ATTACHMENT
function* getAllAttachment({ payload: { documentId } }) {
  try {
    const response = yield call(getAllDocumentAttachmentAPI, documentId.id);
    yield put(getAllAttachmentSuccess(response));
  } catch (error) {
    yield put(getAllAttachmentError(error));
  }
}

//REQUIRED_PROCESS
function* getRequiredProcess() {
  try {
    const response = yield call(getAllRequiredWorkFlowProcessAPI);
    yield put(getRequiredProcessSuccess(response));
  } catch (error) {
    yield put(getRequiredProcessError(error));
  }
}

//METADATA
function* getAllMetadata({ payload: { documentId } }) {
  try {
    const response = yield call(getDocumentMetadataAPI, documentId.id);
    yield put(getAllMetadataSuccess(response));
  } catch (error) {
    yield put(getAllMetadataError(error));
  }
}

function* postMetadata({ payload: { data, documentId } }) {
  try {
    const response = yield call(postDocumentMetadataAPI, data, documentId.id);
    yield put(postMetadataSuccess(response));
  } catch (error) {
    yield put(postMetadataError(error));
  }
}

function* workFlowSaga() {
  yield takeEvery(GET_ALL_ATTACHMENT, getAllAttachment);
  yield takeEvery(GET_REQUIRED_PROCESS, getRequiredProcess);
  yield takeEvery(GET_ALL_METADATA, getAllMetadata);
  yield takeEvery(POST_METADATA, postMetadata);
}

export default workFlowSaga;
