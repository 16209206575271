import React, { useEffect, useState } from "react";


import { useStyles, Wrapper, WrapperLabeling } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import Title from "../../components/title/title";
import { Container } from "reactstrap";
import LoadingComponent from "../../components/loading";
import { DocumentListDocPlace } from "./documentList";
import { FieldsDocPlace } from "./fields";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import ReactRegion from "./RegionSelectP";
import "./styles.css";

import CreateIcon from '@mui/icons-material/BorderColor';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import SaveIcon from "@mui/icons-material/Save";

import FingerPrintIcon from "@mui/icons-material/BorderColor";

import initialDocument from "../../assets/initialDocument.png";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import withReactContent from "sweetalert2-react-content";

import Swal from "sweetalert2";

import TextField from "@mui/icons-material/SortByAlpha";
import Name from "@mui/icons-material/Person";
import Calendar from "@mui/icons-material/Abc";

import DateIcon from "@mui/icons-material/DateRange";
import TimeStampIcon from "@mui/icons-material/AccessTime";


import {
  getEsignatureData,
  saveLabeledData,
  deleteLabeledData,
  updateLabeledData,
  requiredWorkflowProcessAttend,
  getDepartments
} from "../../services/approval";
import { ToastContainer, toast } from "react-toastify";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentPageList } from "../../store/document/actions";

import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";



const useStylesx = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  p: 4,
};
var firstLoad = true;
var running = false;
var _regions = [];

export function ApprovaProcess(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [docTypeID, setDocTypeID] = React.useState("");
  const [regions, setRegions] = React.useState([]);
  const [document, setDocument] = useState("");
  const [startPage] = useState(true);
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState("Not selected");
  const [currentEmail, setCurrentEmail] = useState("");
  const [documents, setDocuments] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading documents");
  const [deleteLabels, setDeleteLabels] = useState(false);
  const [deleteOCR, setDeleteOCR] = useState(false);
  const [counter, setCounter] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);
  const [currentLabel, setCurrentLabel] = useState("");
  const [departments, setDepartments] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);

  const [enableTrain, setEnableTrain] = useState(false);

  const [order, setOrder] = useState(false);

  const [theDoc, setTheDoc] = useState({});

  const [updating, setUpdating] = useState(false);

  const [ocrShow, setOcrShow] = useState(false);

  const [valueText, setValueText] = useState("");

  const [openSave, setOpenSave] = useState(false);

  const [currentDocument, setCurrentDocument] = useState(initialDocument);

  const [saving, setSaving] = useState(true);

  const [showText, setShowText] = useState(false);

  const [currentOrder, setCurrentOrder] = useState(0);

  const [numPages, setNumPages] = useState(2);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  //Contains all regions
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [colorArray, setColorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  const { responseDocumentPages, errorDocumentPages, loadingP } = useSelector(
    (state) => ({
      responseDocumentPages: state.DetailFolder.responseDocumentPages,
      errorDocumentPages: state.DetailFolder.errorDocumentPages,
      loadingP: state.DetailFolder.loadingDocumentPages,
    })
  );


  let params = useParams();
  const handleClose = () => {
    setOpen(false);
    setOcrShow(false);
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const classesx = useStylesx();

  const [trainResult, setTrainResult] = React.useState([]);




  useEffect(() => {
    if (params.id !== "null" && params.id) {
      dispatch(getDocumentPageList({ id: params.id }, 1, 10, navigate));
    } else {
      navigate("/explorer");
    }
  }, [startPage]);

  useEffect(() => {
    if (params.id) {
      setDocTypeID(params.id);
    }
    if (startPage && responseDocumentPages && responseDocumentPages.data) {

      setLoading(true);
      getDepartments(params.id, (event) => { })
        .then((response) => {
          setDepartmentsList(response.data);
        })
        .catch((error) => {
          toast.error("Error loading departments: " + error);
        })
        .finally(() => {
          setLoading(false);
        });

      //@params props.documentTypeID
      setLoading(true);
      getEsignatureData(params.id, (event) => { })
        .then((response) => {

          if (Object.keys(response.data).length == 0) {
            console.log("No data, then get files.");
            let response = responseDocumentPages.data.fileReferences.pages
            setUpdating(false);
            let sampleFiles = response;
            if (sampleFiles && sampleFiles.length > 0) {

              let newArray = []

              sampleFiles.map((item) => {
                newArray.push(
                  {
                    thumbnail: item,
                    fileName: item
                  }
                )
              })
              let docRegions = new Array(sampleFiles.length).fill([]);
              setAllRegions(docRegions);

              docArray = newArray


              setDocuments(docArray);
              setDocument(docArray[0].thumbnail);
              setCurrentDocument(docArray[0].thumbnail);
              setRegions([]);
              _regions = [];
            }

            setUpdating(false);
          } else {
            console.log("Data for this document, has been found.")



            let data = response.data;




            //setLabels(response.data.labels)
            setRegions([]);
            _regions = [];
            setFields(data.labels);


            setDocument(data.documents[0].thumbnail);
            setCurrentDocument(data.documents[0].thumbnail);

            setCurrent("Not selected");
            setDocuments(data.documents);
            setCurrentDocId(-1);
            setCounter(data.counter);
            setCurrentLabelIndex(data.currentLabelIndex);
            setDepartments(data.departments);
            //setTheDoc(data.theDoc);
            setAllRegions(data.allRegions);

            let enable = false;
            let tempDocs = data.documents;

            for (var i = 0; i < data.allRegions.length; i++) {
              let obj = data.allRegions[i];

              let newArray = obj.filter(function (el) {
                return el.type == 1 && el.data.label != ""
              })
              if (newArray.length > 0) {
                setEnableTrain(true);
                break
              }
            }

            setUpdating(true);
          }
        }).catch((error) => {
          toast.error("There is an error loading the document info. Please contact your administrator." + error)

        })
        .finally(() => {
          setLoading(false);
        });





      firstLoad = false;

    }
  }, [startPage, responseDocumentPages]);

  useEffect(() => {
    try {
      _regions = [...regions];
      if (currentDocId >= 0) {
        let allRegionsTemp = allRegions;
        allRegionsTemp[currentDocId] = regions;
        setAllRegions(allRegionsTemp);
      }
    } catch (error) {
      console.log("Error creating regions from OCR: " + error);
    }
  }, [allRegions, regions]);

  const deleteRegion = (label) => {
    setSaving(false);
    resetRegionByLabel(label);
    if (label == current) {
      setCurrent("Not selected");
    }
  };

  function checkRequestSignature() {
    let result = false;
    let ar = [...allRegions]
    for (var i = 0; i < ar.length; i++) {
      let obj = ar[i];

      let newArray = obj.filter(function (el) {
        return el.type == 1 && el.data.label != ""
      })
      if (newArray.length > 0) {
        setEnableTrain(true);
        result = true
        break
      }
    }
    setEnableTrain(result);

    return result;
  }
  const resetRegionByLabel = (label) => {
    let data;
    allRegions.map((iRegions) => {
      for (let item in iRegions) {
        if (iRegions[item].data.label == label) {

          data = iRegions[item].data;
          data.label = "";
          data.regionStyle = regionStyleStart;
          iRegions[item].data = data;
        }
      }
    });
  };
  const onChange = (regions) => {
    _regions = [...regions];
    setRegions(regions);
  };


  const handleOrder = (user_order) => {
    setOrder(user_order)
  }
  const deleteText = (text, xlabel) => {
    setSaving(false);
    let nowRegions = [];
    let index = 0;
    allRegions.map((iRegions) => {
      if (index === currentDocId) {
        for (let item in iRegions) {
          //find Label
          let data = iRegions[item].data;
          if (typeof data.label !== "undefined") {
            if (data.label == xlabel && data.text === text) {
              data.label = "";
              data.regionStyle = regionStyleStart;
              iRegions[item].data = data;
            }
          }
        }
      }
      nowRegions.push(iRegions);
      index++;
    });

    setAllRegions(nowRegions);
  };
  const deleteRegionById = (id) => {
    let temp = [...allRegions];
    temp[currentDocId].splice(id, 1); // 2nd parameter means remove one item only
    setAllRegions(temp);
  };
  const selectRegion = (event, id, data, type) => {

    switch (event.detail) {
      case 1: {
        setSaving(false);
        //let regionP = regions[id];
        let regionP = [...regions];
        console.log(data)

        if (current !== "Not selected" && document !== "") {
          //asdf
          regionP[id].data = {
            label: current,
            email: currentEmail,
            freeText: data.freeText,
            name: regionP[id].data.name,
            text: regionP[id].data.text,
            boundingBoxes: regionP[id].data.boundingBoxes,
            width: regionP[id].data.width,
            height: regionP[id].data.height,
            regionStyle: regionStylePicker(currentOrder),
          };

          setRegions(regionP);
          _regions = [...regionP];
        } else {
          toast.warning("To start, select a recipient and a document first.");
        }
        break;
      }
      case 2: {

        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: "Remove the signature?",
          text: "",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
        }).then((willDelete) => {
          if (willDelete.value) {
            deleteRegionById(id);
          }
        });



        break;
      }

      default: {
        break;
      }
    }



  };
  //cmx
  const handleText = () => {
    setShowText(true)
  }
  const addTextComponent = () => {
    createRegion(4, valueText)
    setShowText(false)
    setValueText("")
  }
  const createRegion = (label, valueText = null) => {
    setCounter(counter + 1);

    let text = ""
    if (label == 1) {
      text = "esignature #"
    }
    if (label == 2) {
      text = "initials #"
    }
    if (label == 3) {
      text = "full name #"
    }
    if (label == 4) {
      text = "text #"
    }
    if (label == 8) {
      text = "date #"
    }
    if (label == 9) {
      text = "timestamp #"
    }

    setRegions((regions) => [
      ...regions,
      {
        x: 10,
        y: 10,
        type: label,
        width: 10,
        height: 6,
        data: {
          text: text + (counter + 1),
          polygon: [],
          boundingBoxes: [],
          width: 10,
          height: 10,
          name: "",
          freeText: valueText,
          label: label,
          regionStyle: {
            background: "rgb(255,255,255,20%)",
          },
        },
      },
    ]);

    let temp = allRegions;
    temp[currentDocId] = regions;
    setAllRegions(temp);
    _regions = [...regions];
  };

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  const requestEsignature = () => {


    setLoadingText("Starting approval process.");



    if (checkRequestSignature() == true) {
      //setLoadingText("Requesting eSignature");
      setLoading(true);

      let teams = [...departments];
      let pages = [];
      let signers = []
      let result = [];

      //Iterate signers

      teams.map((team, index) => {
        //Todo: optimize
        team.listers.map((signer) => {
         
          
          if (signer.checked == true) {
          let objects = [];
          pages = [];
          allRegions.map((iRegion, index) => {
           
            objects = [];

            let item = iRegion.filter(
              (reg) => reg.data.label === signer.id
            );
          
            if (item.length > 0) {

              for (var i = 0; i < item.length; i++) {
                let value = item[i].data.label;

                if (item[i].type == 2) {
                  value = getInitials(item[i].data.label)
                }

                if (item[i].type == 4) {
                  value = item[i].data.freeText
                }

                let currentDate = new Date();
                let formattedDate = ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + currentDate.getDate()).slice(-2) + "-" + currentDate.getFullYear();

                if (item[i].type == 8) {
                  value = formattedDate;
                }
                let currentTime = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();

                if (item[i].type == 9) {
                  value = formattedDate + " " + currentTime;
                }

                objects.push(
                  {
                    "type": item[i].type,
                    "x": item[i].x,
                    "y": item[i].y,
                    "width": item[i].width,
                    "height": item[i].height,
                    "value": value
                  }
                )
              }
              pages.push(
                {
                  "page": index,
                  "items_position_over_document": objects
                }
              )
            }
          })


          
          signers.push(
            {
              "name": signer.name,
              "email": signer.email,
              "team_id": signer.idDepartment,
              "team_order": signer.teamOrder.toString(),
              "order": signer.order.toString(),
              "required_workflow_process_attend_type": signer.checked == true ? 1 : 0,
              "pages": pages

            }
          )
       
        }
       
        
        })
      })

      result =
      {
        "document_id": params.id,
        "user_order": order.toString(),
        "signers": signers,
      }





      requiredWorkflowProcessAttend(result, (event) => { })
        .then((response) => {
          toast.success('The approval process has been started.')
          //saveLabels();

        })
        .catch((error) => {
          toast.error("Error sending data: " + error);
        })
        .finally(() => {
          setLoading(false);
        });



    } else {
      toast.warning("You must assign at least one signature to the document.")
    }
  }



  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      return <div></div>;
    }
  };



  const regionStyleStart = {
    background: "rgba(255,255,255, 20%)",
    border: "1px solid black",
    borderRadius: "10px"
  };
  const regionStyle = {
    background: "rgba(255,255,0, 20%)",

    border: "1px solid black",
    borderRadius: "10px"
  };

  const regionStylePicker = (i) => {

    return {
      background: `rgba(${colorArray[i]}, 20%)`,

      border: "1px solid black",
      borderRadius: "10px"
    }
  };



  const getDocument = (
    document,
    id,
    fileName,
    filePath,
    ocr,
    doc,
    fileOriginalName
  ) => {
    setTheDoc(doc);

    setRegions([]);
    _regions = [];
    setRegions(allRegions[id]);
    _regions = [allRegions[id]];
    setCurrentDocId(id);
    setDocument(document);
    setCurrentDocument(document);
  };


  const selectLabel = (label, i, text, val) => {


    setSaving(false);
    setCurrentLabelIndex(i);
    setCurrent(label);
    setCurrentEmail(text.email)

    setCurrentOrder(val.order)

  };

  const getLabels = (labels, added) => {

    if (added) {
      setSaving(false);
    }
    setLabels(labels);
  };

  const deleteData = (opt) => {
    setSaving(false);
    setLoadingText("Deleting data.");
    setLoading(true);

    deleteLabeledData(params.id, (event) => { })
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        window.location.reload(false);
        // toast.error("Error deleting the data: " + error);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
        checkRequestSignature();
      });
  };

  const saveAndClose = (dp) => {

    saveLabels(null, null, true, true, dp)
    //
  }

  /* Persist data */
  const saveLabels = (docdata, docArray, close, reload, dp) => {
    var dpm = dp;
    if (dpm == null || dpm == undefined) {
      dpm = departments;
    }
    setLoadingText("Saving data");
    setLoading(true);
    let _documents, _allRegions;
    if (docdata) {
      _documents = docdata;
      _allRegions = docArray;
    } else {
      _documents = [...documents];
      _allRegions = [...allRegions];
    }

    let _document = document;

    if (document == "") {
      setDocument(_documents[0].thumbnail);
      _document = _documents[0].thumbnail;
    }
    let data = {
      region: regions,
      allRegions: _allRegions,
      labels: labels,
      document: _document,
      current: current,
      documents: _documents,
      currentDocId: currentDocId,
      counter: counter,
      currentLabelIndex: currentLabelIndex,
      doc: theDoc,
      departments: dpm
    };
    if (updating == true) {
      updateLabeledData(params.id, data, (event) => { })
        .then((response) => {
          toast.success("Document has been updated");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error updating the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    } else {
      saveLabeledData(params.id, data, (event) => { })
        .then((response) => {
          setUpdating(true);
          toast.success("Document changes has been saved");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error saving the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          checkRequestSignature();
          if (reload) {
             window.location.reload(false);
          }
        });
    }
     window.location.reload(false);
  };

  var docArray = [];
  var allDocuments = [];
  var theDocuments = [];
  var theDocArray = [];








  return (
    <>
      <LoadingComponent show={loading} text={loadingText} />

      {
        // Train OCR MODAL
      }

      <Modal
        open={showText}
        onClose={() => setShowText(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add text
          </Typography>




          <FormControl fullWidth sx={{ mt: 2 }}>

            <FormGroup>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                className={classes.ltInput}
                placeholder=""
                autoFocus
                inputProps={{ "aria-label": "add label" }}
                value={valueText}
                onChange={(event) => setValueText(event.target.value)}

              />
            </FormGroup>


          </FormControl>




          <Button
            variant="contained"
            color="success"
            onClick={() => addTextComponent()}
            sx={{ mt: 2, mr: 2 }}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowText(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>

        </Box>
      </Modal>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action will delete all objects created for  the  current
            document and its pages. It will also remove all recipients created. <br />
            <br /> Are you sure you want to continue?
          </Typography>

          <Button
            variant="contained"
            color="info"
            onClick={() => setOpen(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => deleteData(0)}
            sx={{ mt: 2, float: "right" }}
          >
            Yes Delete
          </Button>
        </Box>
      </Modal>

      <MetaTags>
        <title>Approval Process</title>

      </MetaTags>
      <Container fluid>
        <Title title={"Approval Process"} viewActive={false} />
      </Container>
      <WrapperLabeling>
        <ToastContainer autoClose={3000} />
        <Wrapper>
          <Grid container className="toolbarLabeling" >
            <Grid item xs={2} md={2}>




              {/*
            <object width="100%" height="400"  data="https://docplacedevsignatures.blob.core.windows.net/document-esignature/f0bbcae9-bacf-4b5c-97d0-0edf9776e386/5f9cb6d7-213b-4055-a7f0-5aeee47bfb93/Full_01Aaaab copy 2_copy%281%29.pdf" type="application/pdf">   </object>

<Document file="https://docplacedevsignatures.blob.core.windows.net/document-esignature/f0bbcae9-bacf-4b5c-97d0-0edf9776e386/5f9cb6d7-213b-4055-a7f0-5aeee47bfb93/Full_01Aaaab copy 2_copy%281%29.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    */}
              <IconButton
                color="primary"
                style={{ marginRight: "10px" }}
                title="Save"
                aria-label="Save"
                component="label"
                onClick={() => saveLabels()}>
                <SaveIcon />
              </IconButton>

              <IconButton
                onClick={() => setOpen(true)}
                color="error"
                title="Reset data"
                style={{ marginRight: "10px" }}
                aria-label="Delete "
                component="label"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8} md={8} sx={{ textAlign: 'left' }}>
              <Button
                color="primary"
                component="label"
                onClick={() => createRegion(1)}
                style={{ marginLeft: '10px', width: '20px', textAlign: 'left' }}
                variant="contained"
                size="small"
                title="Add eSignature"
                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1
                }
              >
                <CreateIcon />
              </Button>
              <Button
                color="primary"
                component="label"
                onClick={() => createRegion(2)}
                style={{ marginLeft: '10px', width: '20px', textAlign: 'left' }}
                variant="contained"
                size="small"
                title="Add Initials"

                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1
                }
              >
                <TextField />
              </Button>

              <Button
                color="primary"
                component="label"
                onClick={() => createRegion(3)}
                style={{ marginLeft: '10px', width: '20px', textAlign: 'left' }}
                variant="contained"
                size="small"
                title="Add full name"

                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1

                }
              >
                <Name />
              </Button>
              <Button
                color="primary"
                component="label"
                onClick={handleText}
                style={{ marginLeft: '10px', width: '20px', textAlign: 'left' }}
                variant="contained"
                size="small"
                title="Add text"

                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1

                }
              >
                <Calendar />
              </Button>
              <Button
                color="primary"
                component="label"
                onClick={() => createRegion(8)}
                style={{ marginLeft: '10px', width: '20px', textAlign: 'left' }}
                variant="contained"
                size="small"
                title="Add date (mm-dd-yyyy)"

                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1

                }
              >
                <DateIcon />
              </Button>
              <Button
                color="primary"
                component="label"
                onClick={() => createRegion(9)}
                style={{ marginLeft: '10px', width: '20px', textAlign: 'left' }}
                variant="contained"
                size="small"
                title="Add Time stamp (mm-dd-yyyy hh:mm:ss)"

                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1

                }
              >
                <TimeStampIcon />
              </Button>
            </Grid>
            <Grid item xs={2} md={2} style={{ paddingRight: '20px' }}>

              <Button
                title="Start approval process"
                variant="contained"
                size="small"
                onClick={requestEsignature}
                enable

                style={{ marginLeft: "10px", width: '100%' }}
                color="success"
                component="span">
                Start approval
              </Button>

            </Grid>
          </Grid>
        </Wrapper>
        <Grid container >
          <Grid className={classes.wrapperAside} item xs={2} md={2}>
            <DocumentListDocPlace
              documents={documents}
              deleteOCR={deleteOCR}
              getDocument={getDocument}
            />
          </Grid>
          <Grid className={classes.wrapperAside} item xs={8} md={8}>
            <div className="documentShowcase">
              <ReactRegion
                maxRegions={1000}
                regions={regions}
                onChange={onChange}
                regionStyle={regionStyle}
                regionRenderer={regionRenderer}
                constraint
                onClick={selectRegion}
              >
                <img
                  style={{ width: "100%", pointerEvents: "none" }}
                  src={currentDocument}
                  alt="Current document"
                />
              </ReactRegion>
            </div>
          </Grid>
          <Grid className={classes.wrapperAside} item xs={2} md={2}>
            <div style={{ padding: "10px" }} >

              <FieldsDocPlace
                colorArray={colorArray}
                tagKey={Math.random().toString(36).substr(2, 9)}
                regions={regions}
                setFields={fields}
                deleteLabels={deleteLabels}
                deleteText={deleteText}
                getLabels={getLabels}
                selectLabel={selectLabel}
                deleteRegion={deleteRegion}
                order={handleOrder}
                saveSigners={saveAndClose}
                departments={departments}
                departmentsList={departmentsList}

              />
            </div>
          </Grid>
        </Grid>
      </WrapperLabeling>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingTree: state.loadingFolders,
    tree: state.folders,
  };
};

export default connect(mapStateToProps, {})(ApprovaProcess);
