import React from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

export const MetadataForm = (props) => {
  const [metadataSend, setMetadataSend] = React.useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const dynamicContent = {
      [data.key]: data.value,
    };
    setMetadataSend({
      content: dynamicContent,
      comments: "",
    });
    console.log("Data", data);
    reset();
  };

  console.log("metadataSend", metadataSend);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "75%",
        }}
      >
        <div>
          <TextField
            id="content"
            label="Add label"
            variant="outlined"
            {...register("content", { required: "Label is required" })}
            error={!!errors.content}
            helperText={errors.content ? errors.content.message : ""}
            size={"small"}
            fullWidth={true}
          />
        </div>

        <div>
          <TextField
            id="comments"
            label="Add value"
            variant="outlined"
            {...register("comments", { required: "Value is required" })}
            error={!!errors.comments}
            helperText={errors.comments ? errors.comments.message : ""}
            size={"small"}
            fullWidth={true}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit" variant="contained" endIcon={<SaveIcon />}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
