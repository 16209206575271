import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./style";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import CloseMenu from "@mui/icons-material/KeyboardArrowDown";
import OpenMenu from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ImageIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowDown from '@mui/icons-material/ArrowCircleDownRounded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { Autocomplete, TextField } from "@mui/material";
import { getUserList } from "../../store/user/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeesDepartments
} from "../../services/approval";
import { de } from "date-fns/locale";
import { Input } from "reactstrap";

const style = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  width: "40%",
  height: "80%",
  bgcolor: "background.paper",
  p: 4,
};
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top


export function FieldsDocPlace(props) {
  const classes = useStyles();
  const [labels, setLabels] = useState([{ label: "" }]);
  const [label, setLabel] = useState("");
  const [modalLabel, setModalLabel] = useState("");
  let params = useParams();
  const [fmtCurrency, setFmtCurrency] = useState(false);

  const [optional, setOptional] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fmtDate, setFmtDate] = useState(false);
  const [fmtString, setFmtString] = useState(true);

  const [currentLabel, setCurrentLabel] = useState("");

  const [labelType, setLabelType] = React.useState("string");
  const [labelFormat, setLabelFormat] = React.useState("not-specified");
  const [startPage] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [openSigners, setOpenSigners] = React.useState(false);

  const [users, setUsers] = React.useState("");
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [showOrder, setShowOrder] = React.useState(false);


  const [signatures, setSignatures] = React.useState([{ id: "", order: 1, name: "", email: "" }]);
  const [departments, setDepartments] = React.useState([]);

  const [tempSignatures, setTempSignatures] = React.useState([]);

  const [selectedDepartment, setSelectedDepartment] = React.useState(0);
  const [selectedDepartmentName, setSelectedDepartmentName] = React.useState("");
  const [listOfDepartments, setListOfDepartments] = React.useState(props.departmentsList);


  const { responseUser, loadingUser } = useSelector(state => ({
    responseUser: state.User.responseUser,
    loadingUser: state.User.loadingUser,
  }))


  const handleClose = () => setOpen(false);


  useEffect(() => {
    if (props.departments && props.departments.length > 0) {
      let ordered = props.departments.sort((a, b) => a.order - b.order);

      setDepartments(ordered)
    }
  }, [props.departments]);

  useEffect(() => {
    if (props.departmentsList && props.departmentsList.length > 0) {
      setListOfDepartments(props.departmentsList)
      console.log("props.departmentsList", props.departmentsList)
    }
  }, [props.departmentsList]);

  useEffect(() => {
    props.getLabels(labels);
  }, [labels]);

  useEffect(() => {
    setLabels(props.setFields);
    if (props.setFields.length > 0) {
      let temp = [];
      props.setFields.map((item, i) => {
        let order = item.order ? item.order : i
        temp.push({ order: order, name: item.label, id:item.id, email: item.email, external: item.external, cc: item.cc })

      })
      setSignatures(temp)
    }

  }, [props.setFields]);

  useEffect(() => {
    if (props.deleteLabels === true) setLabels([{ label: "" }]);
  }, [props.deleteLabels]);







  const selectLabel = (label, i, text, val) => {
    setCurrentLabel(label);

    props.selectLabel(label, i, text, val);

  };

  const addLabel = (i, signature, email, type, cc) => {
    let newLabel = labels;
    let data = {
      label: signature,
      email: email,
      order: i,
      external: type,
      cc: cc
    };
    newLabel[i] = (data);
    setLabel("");
    props.getLabels(labels, true);
  };
  const deleteLabel = (txt) => {
    let newLabel = labels;
    newLabel = newLabel.filter((e) => e.label !== txt);
    setLabels(newLabel);
    setLabel("");
    handleClose();

    deleteAllTags(txt);
    props.deleteRegion(txt);

    let newS = signatures;
    newS = newS.filter((e) => e.name !== txt);
    setSignatures(newS);


  };
  const [colorArray, setColorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  const isSelected = (text) => {
    try {
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (freelancer) {
          return freelancer.data.label == text;
        });
        //We found the match, hence it has been selected
        return typeof result[0] !== "undefined";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteText = (text, label) => {
    props.deleteText(text, label);
  };

  const deleteAllTags = (label) => {
    try {
      //console.log(label)
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item) {
          return item.data.label == label;
        });
        //We found the match, hence it has been selected
        result.map((item, i) => {
          deleteText(item.data.text, label);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const trimLabel = (label, limit) => {
    var dots = "...";
    if (label.length > limit) {
      label = label.substring(0, limit) + dots;
    }

    return label;
  }



  const showText = (label) => {
    try {
      //console.log(label)
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item) {
          return item.data.label == label;
        });
        //We found the match, hence it has been selected
        return (
          <>
            {result.map((item, i) => (
              <span
                key={i + 9999}
                /* onClick={() => deleteText(item.data.text, label)} */
                className={classes.textListItem}
              >
                {" "}
                {item.data.text}
              </span>
            ))}{" "}
          </>
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showSigners = () => {
    let temp = [];
    temp = signatures.map(object => ({ ...object }))
    setOpenSigners(true)
  };

  const handleCloseSigners = (event, reason) => {
    setOpenSigners(false);
  }

  const addSignature = () => {


    if (selectedDepartment == 0) {
      toast.error("Please select a department")
      return
    }
    if (selectedDepartmentName == "") {
      toast.error("Please select a department")
      return
    }
    if (departments.some(department => department.name === selectedDepartmentName)) {
      toast.error("Department already selected");
      return;
    }


    getEmployeesDepartments(params.id,selectedDepartment, (event) => { })
    .then((response) => {
      
      let department = [...departments];
      
      let employee = [];

      response.data.map((item, i) => {
        employee.push({ order: i, id: item.id, name: item.fullName, idDepartment: selectedDepartment, teamOrder: 0,   required: true, checked: false, email: item.email})
      })

      department.push({ id: selectedDepartment, order: 0, name: selectedDepartmentName, listers: employee, show:true })
  
      setDepartments(department)

    })
    .catch((error) => {
      toast.error("Error loading departments: " + error);
    })
    .finally(() => {
      //
    });

  


  };


  const updateRecipients = () => {
    //sfd
    console.log("departments", departments)
   /* signatures.map((item, i) => {
      addLabel(i, item)
      //props.deleteRegion(item.name)

    })*/
    if (props.saveSigners) {

      props.saveSigners(departments)
    }
    setOpenSigners(false);
  }

  useEffect(() => {
    dispatch(getUserList({ page: 1, pageSize: 20 }, navigate))
  }, startPage)


  useEffect(() => {
    if (responseUser && responseUser.data) {
      let newArray = [];
      responseUser.data.map((item, i) => {
        newArray.push({ label: item.fullName, email: item.email })
      })
      setUsers(newArray);
    }
  }, [responseUser])







  const checkAllListers = (name, checked) => {
    let signs = [...departments];
    signs.map((item, j) => {
      if (item.name == name)
        item.listers.map((lm, k) => {
          lm.checked = checked
        })
    })

    setDepartments(signs)
  }

  const handleCheckboxChange = (name, id) => {
    let signs = [...departments];

    signs.map((item, j) => {
      if (item.name == name) {

        item.listers.map((lm, k) => {
          if (lm.id == id) {
            lm.checked = !lm.checked
          }
        })
      }
    })

    setDepartments(signs)

  }

  const DisplayListers = (props) => {
    var dep = props.deparment;

    return props.listers.map((lm, j) => {
      return (
        <ListItem className="listItem" key={lm.name + j + 10000}>
          <Checkbox
            checked={lm.checked}
            label="Required"
            onChange={(event) => handleCheckboxChange(dep, lm.id)}
            key={lm.name + j + 1000}
          />
          <ListItemText key={lm.name + j + 2000} primary={lm.name} secondary={lm.email} />

        </ListItem>
      )
    }
    )
  }


  const toggleDepartment = (id) => {
    let depas = [...departments];
    const departmentId = id;
    depas.map((item, i) => {
      if (item.id == departmentId) {
        item.show = !item.show;
      }
    })
    setDepartments(depas)
  };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
  
      return result;
    };
  
  const handleDragEnd = (result) => {
  
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let departmentsx = [...departments]
    const items = reorder(
      departmentsx,
      result.source.index,
      result.destination.index
    );


    

    items.map((item, i) => {
      item.order = i + 1
    })
    console.log("items", items)
    
    setDepartments([])
    setDepartments(items)
  }
useEffect(() => {
  console.log("departments", departments)
}
, [departments])

const handleMoveUp = (item, departmentId) => {
  const department = departments.find((dept) => dept.id === departmentId);
  const index = department.items.findIndex((deptItem) => deptItem.id === item.id);

  if (index > 0) {
    const previousItem = department.items[index - 1];
    const updatedItems = [...department.items];

    // Swap the order property of the current item and the previous item
    const tempOrder = item.order;
    item.order = previousItem.order;
    previousItem.order = tempOrder;

    // Update the items array with the modified order
    updatedItems[index] = item;
    updatedItems[index - 1] = previousItem;


    // Update the department with the updated items array
    const updatedDepartment = { ...department, items: updatedItems };

    // Update the departments array with the modified department
    const updatedDepartments = departments.map((dept) =>
      dept.id === departmentId ? updatedDepartment : dept
    );

    // Update the state with the modified departments array
    setDepartments(updatedDepartments);
  }
};

const handleMoveDown = (item, departmentId) => {
  const department = departments.find((dept) => dept.id === departmentId);
  const index = department.items.findIndex((deptItem) => deptItem.id === item.id);

  if (index < department.items.length - 1) {
    const nextItem = department.items[index + 1];
    const updatedItems = [...department.items];

    // Swap the order property of the current item and the next item
    const tempOrder = item.order;
    item.order = nextItem.order;
    nextItem.order = tempOrder;

    // Update the items array with the modified order
    updatedItems[index] = item;
    updatedItems[index + 1] = nextItem;

    // Update the department with the updated items array
    const updatedDepartment = { ...department, items: updatedItems };

    // Update the departments array with the modified department
    const updatedDepartments = departments.map((dept) =>
      dept.id === departmentId ? updatedDepartment : dept
    );

    // Update the state with the modified departments array
    setDepartments(updatedDepartments);
  }
};

const canMoveUp = (item,departmentId) => {
  let depas = [...departments];
  let department = depas.find(x => x.id == departmentId);
  let listers = department.listers;
  let index = listers.findIndex(x => x.id == item.id);
  return index > 0;
}

const canMoveDown = (item,departmentId) => {
  let depas = [...departments];
  let department = depas.find(x => x.id == departmentId);
  let listers = department.listers;
  let index = listers.findIndex(x => x.id == item.id);
  return index < listers.length - 1;
}

  return (
    <>

        <Modal
          open={openSigners}
          onClose={handleCloseSigners}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Approval Process 
            </Typography>
            <Typography
              component={"div"}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Add the information of Departments. You can arrange the order in which the signatures must be collected. When all departments have been added, click on the Save button.
            </Typography>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormGroup className="departmentSelect">
                <div className="departmentName">
                  <Autocomplete
                    id="combo-box-demo"
                    options={listOfDepartments}
                    getOptionLabel={(option) => option.name}
                    style={{ width: "100%" }}
                    onChange={(event, newValue) => {


                        setSelectedDepartment(newValue.id);
                        setSelectedDepartmentName(newValue.name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Select Department"
                      />
                    )}
                  />

                </div>
                <div className="departmentAdd">
                  <Button variant="contained" color="info" onClick={() => addSignature()} >Add </Button>
                </div>
              </FormGroup>


            </FormControl>


            <div className="cards">

              {departments.map((item, i) => {

                return (
                  <>
                  <div className="card">
                  <Button   onClick={() => { let temp = [...departments]; temp.splice(i, 1); setDepartments(temp) }} sx={{ position: "absolute", marginTop:"-30px" }} key={i + 200} >
                   Remove </Button>

                    <div className="departmentTitle">
                      <Checkbox
                        checked={item.checked}
                        onChange={(event) => checkAllListers(item.name, event.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox' }}
                      />
                      {item.name}
                            </div>
                    <div className="departmentContent">

                      <DisplayListers key={item.name + i} deparment={item.name} listers={item.listers} />



                    </div>
                  </div>
                 
                   </>
                   
                )
              })}

            </div>


            <div className="buttonActions">
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseSigners}
                sx={{ mt: 8, float: "right", width: "100px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => updateRecipients()}
                sx={{ mt: 8, mr: 2, float: "right", width: "100px" }}
              >
                Save
              </Button>

            </div>


          </Box>

        </Modal>
        <div className="addLabel" key={props.tagKey}>
          <Paper
            component="form"
            square
            sx={{ p: "1px", display: "flex", alignItems: "center" }}
          >
            <Button
              variant="contained"
              color="info"
              sx={{ width: '100%', marginBottom: '20px' }}
              onClick={showSigners}
            >
              Departments
            </Button>
          </Paper>

          <div className="labelsList" key={96856}>

          <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="departments">
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {departments.map((dep, dep_i) => (
                              <Draggable key={dep.id} draggableId={dep.id} index={dep_i}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Paper
                                      component="form"
                                      key={dep_i}
                                      variant="outlined"
                                      square
                                      sx={{
                                        p: "1px 1px",
                                        display: "flex",
                                        border: "none",
                                        alignItems: "left",
                                      }}
                                    >
                                      <div
                                        className="bm"
                                        style={{ backgroundColor: `rgb(${colorArray[1]})` }}
                                      ></div>
                                      <div
                                        sx={{ ml: 1, flex: 1 }}
                                        title={`${dep.name}`}
                                        className={classes.ltSelected}
                                        key={dep_i}
                                      >
                                        {" "}
                                        {dep.name}
                                      </div>
                                      <IconButton
                                        onClick={() => toggleDepartment(dep.id)}
                                        color="primary"
                                        sx={{ p: "2px" }}
                                      >
                                        {dep.show ? (
                                          <OpenMenu sx={{ p: "2px" }} key={dep_i + 200} />
                                        ) : (
                                          <CloseMenu sx={{ p: "2px" }} key={dep_i + 200} />
                                        )}
                                      </IconButton>
                                    </Paper>
                                   
                                    {dep.show && dep.listers.map((text, i) =>
                                          text.name !== "" && text.checked == true ? (
                                            <div key={i + 100}>
                                              <Paper
                                                component="form"
                                                className={
                                                  currentLabel == text.id
                                                    ? classes.ltLabelSelected
                                                    : classes.ltLabelUnselected
                                                }
                                                key={i}
                                                variant="outlined"
                                                square
                                                sx={{
                                                  p: "1px 1px",
                                                  display: "block",
                                                  alignItems: "left",
                                                }}
                                              >
                                                <div
                                                  sx={{
                                                    ml: 1,
                                                    flex: 1,
                                                    color: "black !important",
                                                  }}
                                                  title={`${text.email}`}
                                                  onClick={() =>
                                                    selectLabel(text.id, i, text, text)
                                                  }
                                                  className={
                                                    isSelected(text.id)
                                                      ? classes.ltsSelected
                                                      : classes.ltsUnSelected
                                                  }
                                                  key={i}
                                                >
                                                  {trimLabel(text.name, 20)}
                                                  {canMoveUp(text,dep.id) ? <ImageIcon className="imageSVG" onClick={()=>handleMoveUp(text,dep.id)} /> : <ImageIcon className="imageSVGDisabled" />}
                                                  {canMoveDown(text,dep.id) ? <ArrowDown className="imageSVG" onClick={()=>handleMoveDown(text,dep.id)} /> : <ArrowDown className="imageSVGDisabled" />}
                                               
                                                
                                                  
                                                </div>
                                              </Paper>
                                              <div className={classes.textList} key={i + 800}>
                                                {showText(text.id)}{" "}
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                    
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
          </div>



        </div>
     
    </>
  );
}

export default FieldsDocPlace;
