import axios from "axios";

const API_URL = process.env.REACT_APP_HOST_API;

const setToken = () => {
  if (localStorage.getItem("docplace")) {
    return "Bearer " + JSON.parse(localStorage.getItem("docplace")).token;
  }
};

const setUserId = () => {
  if (localStorage.getItem("docplace")) {
    return JSON.parse(localStorage.getItem("docplace")).userId;
  }
};

const setCustomerId = () => {
  if (localStorage.getItem("docplace")) {
    return JSON.parse(localStorage.getItem("docplace")).customerId;
  }
};

const setDoctypeId = () => {
  if (localStorage.getItem("docplace")) {
    return JSON.parse(localStorage.getItem("docplace")).doctypeId;
  }
};

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const setHeaders = () => {
  axiosApi.defaults.headers.common["Authorization"] = setToken();
  axiosApi.defaults.headers.common["customerId"] = setCustomerId();
  axiosApi.defaults.headers.common["doctypeId"] = setDoctypeId();
  axiosApi.defaults.headers.common["userId"] = setUserId();
  axiosApi.defaults.headers.common["name"] = "doc";
};

export async function get(url, config = {}) {
  setHeaders();
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function getDoc(url, config = {}) {
  setHeaders();
  return await axiosApi
    .get(url, { responseType: "blob", ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  setHeaders();
  if (config) {
    axiosApi.defaults.headers.common["documentId"] = config;
  }
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function postDownload(url, data, config = {}) {
  setHeaders();
  if (config) {
    axiosApi.defaults.headers.common["documentId"] = config;
  }
  return axiosApi
    .post(url, [...data], { ...config, responseType: "blob" })
    .then((response) => response.data);
}

export async function dpost(url, data, config = {}) {
  setHeaders();
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}
export async function put(url, data, config = {}) {
  setHeaders();
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  setHeaders();
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, data, config = {}) {
  setHeaders();
  return axiosApi
    .delete(url, { ...config, data })
    .then((response) => response.data);
}
