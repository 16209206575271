import {
  GET_ALL_ATTACHMENT,
  GET_ALL_ATTACHMENT_CLEAR_DATA,
  GET_ALL_ATTACHMENT_ERROR,
  GET_ALL_ATTACHMENT_SUCCESS,
  GET_ALL_METADATA,
  GET_ALL_METADATA_CLEAR_DATA,
  GET_ALL_METADATA_ERROR,
  GET_ALL_METADATA_SUCCESS,
  GET_REQUIRED_PROCESS,
  GET_REQUIRED_PROCESS_CLEAR_DATA,
  GET_REQUIRED_PROCESS_ERROR,
  GET_REQUIRED_PROCESS_SUCCESS,
  POST_METADATA,
  POST_METADATA_CLEAR_DATA,
  POST_METADATA_ERROR,
  POST_METADATA_SUCCESS,
} from "./actionTypes";

//ATTACHMENT
export const getAllAttachment = (documentId) => {
  return {
    type: GET_ALL_ATTACHMENT,
    payload: { documentId },
  };
};

export const getAllAttachmentSuccess = (success) => {
  return {
    type: GET_ALL_ATTACHMENT_SUCCESS,
    payload: success,
  };
};

export const getAllAttachmentError = (error) => {
  return {
    type: GET_ALL_ATTACHMENT_ERROR,
    payload: error,
  };
};

export const getAllAttachmentClearData = () => {
  return {
    type: GET_ALL_ATTACHMENT_CLEAR_DATA,
    payload: {},
  };
};

//REQUIRED_PROCESS
export const getRequiredProcess = (documentId) => {
  return {
    type: GET_REQUIRED_PROCESS,
    payload: { documentId },
  };
};

export const getRequiredProcessSuccess = (success) => {
  return {
    type: GET_REQUIRED_PROCESS_SUCCESS,
    payload: success,
  };
};

export const getRequiredProcessError = (error) => {
  return {
    type: GET_REQUIRED_PROCESS_ERROR,
    payload: error,
  };
};

export const getRequiredProcessClearData = () => {
  return {
    type: GET_REQUIRED_PROCESS_CLEAR_DATA,
    payload: {},
  };
};

//METADATA
export const getAllMetadata = (documentId) => {
  return {
    type: GET_ALL_METADATA,
    payload: { documentId },
  };
};

export const getAllMetadataSuccess = (success) => {
  return {
    type: GET_ALL_METADATA_SUCCESS,
    payload: success,
  };
};

export const getAllMetadataError = (error) => {
  return {
    type: GET_ALL_METADATA_ERROR,
    payload: error,
  };
};

export const getAllMetadataClearData = () => {
  return {
    type: GET_ALL_METADATA_CLEAR_DATA,
    payload: {},
  };
};

export const postMetadata = (data, documentId) => {
  return {
    type: POST_METADATA,
    payload: { data, documentId },
  };
};

export const postMetadataSuccess = (success) => {
  return {
    type: POST_METADATA_SUCCESS,
    payload: success,
  };
};

export const postMetadataError = (error) => {
  return {
    type: POST_METADATA_ERROR,
    payload: error,
  };
};

export const postMetadataClearData = () => {
  return {
    type: POST_METADATA_CLEAR_DATA,
    payload: {},
  };
};
